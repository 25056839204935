<form novalidate autocomplete="off" #form="ngForm" (submit)="onSubmit(form)">
    <input type="hidden" name="paymentDetailId" [value]="service.formData.paymentDetailId" />
    <div class="form-group">
        <label>Tên mẫu</label>
        <input class="form-control form-control-lg" placeholder="Full Name" name="cardOwnerName"
            #cardOwnerName="ngModel" [(ngModel)]="service.formData.cardOwnerName"
            required [class.invalid]="cardOwnerName.invalid && cardOwnerName.touched">
    </div>
    <div class="form-group">
        <label>C</label>
        <input type="number" class="form-control form-control-lg"  name="addC"
            #addC="ngModel" [(ngModel)]="service.formData.addC"
            required maxlength="16" minlength="16">
    </div>
    <div class="form-group">
        <label>Mn</label>
        <input type="number" class="form-control form-control-lg"  name="addMn"
            #addMn="ngModel" [(ngModel)]="service.formData.addMn"
            required maxlength="16" minlength="16">
    </div>
    <div class="form-group">
        <label>Cr</label>
        <input type="number" class="form-control form-control-lg"  name="addCr"
            #addCr="ngModel" [(ngModel)]="service.formData.addCr"
            required maxlength="16" minlength="16">
    </div>
    <div class="form-group">
        <label>Ni</label>
        <input type="number" class="form-control form-control-lg"  name="addNi"
            #addNi="ngModel" [(ngModel)]="service.formData.addNi"
            required maxlength="16" minlength="16">
    </div>
    <div class="form-group">
        <label>Si</label>
        <input type="number" class="form-control form-control-lg"  name="addSi"
            #addSi="ngModel" [(ngModel)]="service.formData.addSi"
            required maxlength="16" minlength="16">
    </div>
    <div class="form-group">
        <label>Người phân tích</label>
        <input class="form-control form-control-lg" placeholder="Full Name" name="personAnalysis"
            #personAnalysis="ngModel" [(ngModel)]="service.formData.personAnalysis">
    </div>

    <div class="form-group">
        <button class="btn btn-info btn-lg btn-block" type="submit" [disabled]="form.invalid">GỬI ĐI</button>
    </div>
</form>