<div class="row">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="text-align: center;"><img src="/assets/image/logo.png" width="150px"/></th>
                    <th style="text-align: center; color: brown;"><h1><b>PHÂN XƯỞNG VÕ QUÝ HUÂN</b></h1></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="2" style="font-size: 25px">Mẫu: {{data.cardOwnerName}} - Phân tích viên: {{data.personAnalysis}} - {{timeNow | date:'medium'}}</td>
                </tr>
                <tr class="table-warning">
                    <td><img src="/assets/image/c.png" width="150px"/></td>
                    <td><b>{{data.addC}} gram </b></td>
                </tr>
                <tr class="table-primary">
                    <td><img src="/assets/image/mn.png" width="150px"/></td>
                    <td><b>{{data.addMn}} gram </b></td>
                </tr>
                <tr class="table-primary">
                    <td><img src="/assets/image/cr.png" width="150px"/></td>
                    <td><b>{{data.addCr}} gram</b></td>
                </tr>
                <tr class="table-primary">
                    <td><img src="/assets/image/ni.png" width="150px"/></td>
                    <td><b>{{data.addNi}} gram</b></td>
                </tr>
                <tr class="table-success">
                    <td><img src="/assets/image/si.png" width="150px"/></td>
                    <td><b>{{data.addSi}} gram </b></td>
                </tr>
            </tbody>
   
        </table>
</div>