<div class="jumbotron py-3">
    <h1 class="display-4 text-center">NHẬP KẾT QUẢ PHÂN TÍCH</h1>
</div>

<div class="row">
    <div class="col-md-6">
        <app-payment-detail-form></app-payment-detail-form>
    </div>
    <div class="col-md-6">
        <table class="table">
            <thead class="thead-light">
                <tr>
                    <th>Tên mẫu</th>
                    <th>Ngày phân tích</th>
                    <th>Người phân tích</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pd of service.list">
                    <td (click)="populateForm(pd)">{{pd.cardOwnerName}}</td>
                    <td (click)="populateForm(pd)">{{pd.createDate}}</td>
                    <td (click)="populateForm(pd)">{{pd.personAnalysis}}</td>
                    <td>
                        <i class="far fa-trash-alt fa-lg text-danger" (click)="onDelete(pd.paymentDetailId)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
