export class PaymentDetail {
    paymentDetailId: number=0;
    cardOwnerName: string='';
    addC: number=0;
    addMn: number=0;
    addCr: number=0;
    addNi: number=0;
    addSi: number=0;
    personAnalysis: string='';
    createDate: string='';
}
