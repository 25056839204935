import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule,Routes } from '@angular/router';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { ResultComponent } from './result/result.component';

const routes: Routes = 
[
  {path: 'home', component: PaymentDetailsComponent},
  {path: 'result', component: ResultComponent}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
