import { interval,Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { PaymentDetail } from 'src/app/shared/payment-detail.model';
import { PaymentDetailService } from 'src/app/shared/payment-detail.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  constructor(public service: PaymentDetailService) { }
  data: any = null;
  timeNow : string = '';
  ngOnInit(): void {
      interval(3000).subscribe(
        (val) => { this.refreshData()});
  }
  refreshData(){
    this.service.getDetailNew().subscribe(res => {
      console.log(res);
      this.data = res;
      this.timeNow = new Date().toISOString();
    })
}

}
